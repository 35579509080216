import React from "react";
import "./TitleBlock.scss"

const TitleBlock = ({title, subtitle, titleFontSize, alignment }) => {

    titleFontSize && document.documentElement.style.setProperty("--title-FontSize", titleFontSize );


    return(
        <>
        <div className={"TitleBlock-container"}>
            {title && <h1 style={{fontSize: titleFontSize && titleFontSize}}className={"TitleBlock-title"}>{title}</h1>}
            {subtitle && <h2 className={"TitleBlock-subtitle"}>{subtitle}</h2>}
        </div>
        </>
    )
}

export default TitleBlock;
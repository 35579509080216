import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import Teamwork from './Teamwork';
import ConflictSimulatorNeeds from './ConflictSimulatorNeeds';
import Discuss from './Discuss';
import SomethingWrong from 'components/SomethingWrong/SomethingWrong';
import { isFunction } from 'lodash';

const LessonStepper = ({
  CHEATS,
  CurrentSelectedStepItem,
  setCurrentSelectedStepItem,
  IsCurrentSelectedStepItemCompleted,
  stepRecipe,
  handleCloseStepper,
  playRefAudio,
  pauseRefAudio,
  teamworkMusicRef,
  VolumeSwitchState,
  addEvent,
  classes,
  MaxStepItems,
  AnimalType,
  instructorName,
  LessonDetails
}) => {
//   console.log("StepProgIndex", StepProgIndex)
const { t } = useTranslation();
const stepItemsRecipe = stepRecipe.stepItems;
console.log('stepRecipe', stepRecipe);

//   console.log("stepItemsRecipe", stepItemsRecipe)

  const [IsVideoWatchedTillEnd, setIsVideoWatchedTillEnd] = useState(false);
  const [VideoSeekValue, setVideoSeekValue] = useState(0);

  const generateStepElement = (stepItemRecipe, onContinue, onBack, onExit, addEvent) => {
    switch (stepItemRecipe.pageState) {
      case 'video':
        return (
          <VideoPlayer
            URL={stepItemRecipe.url}
            IsVideoWatchedTillEnd={IsVideoWatchedTillEnd}
            setVideoSkipped={() => console.log('setVideoSkipped')}
            onContinue={onContinue}
            onBack={onBack}
            onExit={onExit}
            addEvent={addEvent}
            IsCurrentSelectedStepItemCompleted={IsCurrentSelectedStepItemCompleted}
            VideoOnEnded={false}
            setVideoOnEnded={() => console.log('setVideoOnEnded')}
            setVideoExited={() => console.log('setVideoExited')}
            seek={VideoSeekValue}
            volume={VolumeSwitchState}
            classes={'fullScreen '}
            VideoPlayerLoop={false}
            CurrentSelectedStepItem = {CurrentSelectedStepItem}
          />
        );
      break
      case 'teamWork':
        return (
          <Teamwork
            URL={stepItemRecipe.url}
            IsVideoWatchedTillEnd={IsVideoWatchedTillEnd}
            playRefAudio={playRefAudio}
            pauseRefAudio={pauseRefAudio}
            teamworkMusicRef={teamworkMusicRef}
            onContinue={onContinue}
            onBack={onBack}
            addEvent={addEvent}
            CurrentSelectedStepItem = {CurrentSelectedStepItem}
            bullets = {stepItemRecipe.bullets}
            tips = {stepItemRecipe.tips}
            AnimalType ={AnimalType}
            instructorName ={instructorName}
            LessonDetails={LessonDetails}
          />
        );
      break
      case 'discuss':
        return (
          <Discuss
            img={stepRecipe.stepItems[CurrentSelectedStepItem].img}
            discussTitle={stepRecipe.stepItems[CurrentSelectedStepItem].discussTitle}
            discussSubtitle={stepRecipe.stepItems[CurrentSelectedStepItem].discussSubtitle}
            onContinue={onContinue}
            onBack={onBack}
            addEvent={addEvent}
            CurrentSelectedStepItem = {CurrentSelectedStepItem}
          />
        );
      break
      case "needCards" :
          return (
              <ConflictSimulatorNeeds 
                  addEvent={addEvent}
                  CurrentSelectedStepItem = {CurrentSelectedStepItem}
                  cards = {stepRecipe.stepItems[CurrentSelectedStepItem].cards}
                  setConflictSimulatorNeedsReplay = {onBack}
                  backgroundColor = {stepRecipe.stepItems[CurrentSelectedStepItem].backgoundColor}
                  onBack={onBack}
                  thumbnailUrl = {stepRecipe.stepItems[CurrentSelectedStepItem].thumbnailUrl}
              />
          )
      break
      default:
        return null;
    }
  };

  const onContinue = async () => {
    // console.log("onContinue fired")
    // const stepOnContinue = currentStepItem?.onContinue;

    // console.log("IN ON CONTINUE:")
    // console.log("a. CurrentSelectedStepItem:", CurrentSelectedStepItem)
    // console.log("a. MaxStepItems -1 :", MaxStepItems -1)

    // if (isFunction(stepOnContinue)) await stepOnContinue();
    // if (CurrentSelectedStepItem < MaxStepItems -1) {
    //   // setCurrentSelectedStepItem(CurrentSelectedStepItem + 1);
    // } else {
    //   onFinish();
    // }
  };

  const onBack = async () => {
    // const stepOnBack = currentStepItem?.onBack;
    // if (isFunction(stepOnBack)) await stepOnBack();
    // if (CurrentSelectedStepItem >= maxStepIndex && CurrentSelectedStepItem > 0) {
      // setCurrentSelectedStepItem(CurrentSelectedStepItem - 1);
    // }
  };

  const onExit = async () => {
    const stepOnExit = currentStepItem?.onExit;
    if (isFunction(stepOnExit)) await stepOnExit();
    exitStepper();
  };

  // const onTrigger = async (triggerName, ElName, componentName, StepItemIndex) => {
  //   const onTrigger = currentStepItem?.onTrigger;
  //   if (isFunction(onTrigger)) await onTrigger(triggerName);
  //   addEvent(triggerName, ElName, componentName, StepItemIndex); 
  // };

  // const onTrigger = (triggerName, ElName, componentName, StepItemIndex) => {
  //   addEvent(triggerName, ElName, componentName, StepItemIndex); 
  // };

  const onFinish = () => {
    handleCloseStepper();
  };

  const exitStepper = () => {
    console.log('exiting the stepper');
    handleCloseStepper();
  };

  const buildStepItems = (stepItemsRecipe) => {
    return stepItemsRecipe.map((stepItemRecipe, index) => ({
      index: index,
      title: t(stepItemRecipe.title),
      element: generateStepElement(stepItemRecipe, onContinue, onBack, onExit, addEvent),
    //   isContinueDisabled: stepItemRecipe.isContinueDisabled,
    }));
  };

  const stepItemsArr = buildStepItems(stepItemsRecipe);
  

  const stepItems = stepItemsArr.filter(({ stepDisabled }) => !stepDisabled);
  const maxStepIndex = stepItems.length - 1;
  const currentStepItem = stepItems[CurrentSelectedStepItem];

  return (
    <>
      <div className={'' + classes }>
          <div className={' ' + classes + " " + (CHEATS? "CHEATS-relative" : "")}>
            {/* <div className={['CHEATS', 'CHEATS-fixed'].join(' ')}>{'HEY! CurrentSelectedStepItem: ' + CurrentSelectedStepItem}</div> */}
            {currentStepItem?.element ? currentStepItem?.element : <SomethingWrong/>}
          </div>
        </div>
    </>
  );
};

export default LessonStepper;


import LessonPuppetSteps from "./LessonPuppetSteps";
import LessonHouseSteps from "./LessonHouseSteps";
import LessonBackgroundSteps from "./LessonBackgroundSteps";
import LessonTheatreSteps from "./LessonTheatreSteps";
 //  { // intro
  //     size : "S",
  //     name: "course.theatre.lesson-puppet.steps.intro.name", 
  //     description: "course.theatre.lesson-puppet.steps.intro.description",
  //     desciptionTips: ["Tip 1", "Tip 2"],
  //     type: "videoTeamwork",
  //     AnimalName : "koala",
  //     requirements: [{name: "activityPage"} ],
  //     videoThumbnail: "/assets/course/theatre/videos-and-thumbnails/intro-thumbnail.jpg",
  //     stepItems : [
  //       { 
  //         pageState: "video",
  //         url: "https://media.catbears.com/course_theatre/puppet_step-00_intro_he.mp4",
  //         videoThumbnail: "/assets/course/theatre/intro-thumbnail.jpg",
  //         isStepGoal: true
  //       }
  //     ]
  //   },
export const CourseTheatreRecipe =
    {
      "version": 1,
      "pathname": "theatre",
      "topLine": "course.theatre.topLine",
      "name": "course.theatre.courseName",
      "description" : "course.theatre.courseDescription",
      "imgPath" : "assets/course/theatre/course-thumbnail_theatre_03.jpg",
      "activitypage" : "assets/course/activityCards/thumb_theatre_activityPage.jpg",
      "lessonsCount": 4,
      "lessons": [
        // {
        //   type: "LessonVideoItem",
        //   backgroundColor: "var(--color-pink)",
        //   name: "teamworkSkills.wheel-of-creation.name", 
        //   ImageToShow: "wheel-of-creation",
        //   url: "https://media.catbears.com/course_theatre/wheel-of-creation.mp4",
        // },
        {
          "type": "LessonListItem",
          "size": "small",
          "name": "course.theatre.lesson-puppet.name",
          "description" : "course.theatre.lesson-puppet.description",
          "activityPagePath" : "assets/course/activityCards/thumb_puppet_activityPage.jpg",
          "imgPath" : "assets/course/theatre/lesson-thumbnail_puppet.jpeg",
          "badge": "",
          "steps" : LessonPuppetSteps,
          "pathname" : "puppet",
          "instructorName" : "instructors.meek",
          "AnimalType" : "koala",
          "backgroundColor": "var(--color-skyblue)",
          "teaserThumbnail": "/assets/course/theatre/videos-and-thumbnails/intro-thumbnail.jpg",
          "teaserUrl": "https://media.catbears.com/course_theatre/puppet_step-00_intro_he.mp4",
          "activityPages" : "https://media.catbears.com/course_theatre/activity_pages/catbears_make-a-puppet.pdf"                                                       
        },
        {
          "type": "LessonListItem",
          "size": "small",
          "name": "course.theatre.lesson-house.name",
          "description" : "course.theatre.lesson-house.description",
          "activityPagePath" : "assets/course/activityCards/thumb_house_activityPage.jpg",
          "imgPath" : "assets/course/theatre/lesson-thumbnail_house.jpeg",
          "badge": "",
          "steps" : LessonHouseSteps,
          "pathname" : "house",
          "instructorName" : "instructors.eden",
          "AnimalType" : "crow",
          "backgroundColor": "#EF86FF",
          "teaserThumbnail": "/assets/course/theatre/videos-and-thumbnails/step2-thumbnail.jpg",
          "teaserUrl": "https://media.catbears.com/course_theatre/house_step-00_intro.mp4",
          "activityPages" : "https://media.catbears.com/course_theatre/activity_pages/catbears_build-a-house.pdf"
        },
        {
          "type": "LessonListItem",
          "size": "small",
          "name": "course.theatre.lesson-background.name",
          "description" : "course.theatre.lesson-background.description",
          "activityPagePath" : "assets/course/activityCards/thumb_background_activityPage.jpg",
          "imgPath" : "assets/course/activityCards/thumb_background_img.jpg",
          "badge": "comingSoon",
          "steps" : LessonBackgroundSteps,
          "pathname" : "background",
          "instructorName" : "instructors.jordy",
          "AnimalType" : "croc",
          "backgroundColor": "#41B04B",
          "teaserUrl": "https://media.catbears.com/course_theatre/background_intro.mp4",
          "activityPages" : "https://media.catbears.com/course_theatre/activity_pages/catbears_build-a-house.pdf"
        },
        {
          "type": "LessonListItem",
          "size": "XL",
          "name": "course.theatre.lesson-theatre.name",
          "description" : "course.theatre.lesson-theatre.description",
          "activityPagePath" : "assets/course/activityCards/thumb_theatre_activityPage.jpg",
          "imgPath" : "assets/course/activityCards/thumb_theatre_img.jpg",
          "badge": "comingSoon",
          "steps" : LessonTheatreSteps,
          "pathname" : "theatre",
          "instructorName" : "instructors.jasmin",
          "AnimalType" : "bunny",
          "backgroundColor": "var(--color-pink)"
        }
      ]
  }
  
  export default CourseTheatreRecipe;


import React, {useState, useEffect, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import OnboardingCatbears from "../../components/Onboarding/OnboardingCatbears";
import TopNavbar from '../../components/TopNavbar/TopNavbar';
import AllCourses from "../../flows/AllCourses"
import CoursesGallery from "./CoursesGallery/CoursesGallery"
import { useHistory } from 'react-router-dom';

// import {Link} from 'react-router-dom';
// import AnimalsThumbnail from '../../components/AnimalsThumbnail/AnimalsThumbnail';
// import {apiListRelationships} from '../../graphql/queries';
// import gql from 'graphql-tag';
// import {useQuery} from 'react-apollo';
// import catbearsAngry from './catbears_angry.png';
// import catbearsAngry2x from './catbears_angry@2x.png';
// import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
// import SubscriptionModal from "../../components/SubscriptionModal/SubscriptionModal";
// import CourseListItem from 'components/CourseListItem/CourseListItem';
// import HeroPoster from 'components/HeroPoster/HeroPoster';

import './HomePage.scss';

function HomePage({authContext}) {
  const {t} = useTranslation();
  const history = useHistory();

  history.push('/theatre');
  
  return (
    <>

    {/* <TopNavbar
        showNavBG={false}
        showLogo={false}
        authContext={authContext}
        user={authContext.user}
        // title={t(`course.title`)}
        border={false}
        // showMenuButton
        ></TopNavbar> */}

    {/* <div className="homepage texture"> */}
      {/* {!authContext.user.isOnboardingComplete && 
        <OnboardingCatbears authContext={authContext}/>
      } */}
    {/* Course start */}
      {/* {authContext.user.isOnboardingComplete && <div>Show Course Beginning</div>} */}
          {/* </div> */}
    </>
  )
  

  //     {history.push('/theatre');}


  // courses gallery -- start --
  // ADD THIS TO THE TopNavbar
  // title={t(`course.title`)}


  {/* <div className="homepage texture">
      <div className="homepage-content ">
            <CoursesGallery />
        </div>
  </div> */}
// courses gallery -- end --



  // const {loading, error, data: relationshipsData, refetch} = useQuery(gql(apiListRelationships));
  // const CourseData = CourseTheatreFlow;
  // const [relationships, setRelationships] = useState([]);
  // const [isFeedbackModalState, setIsFeedbackModalState] = useState(false);
  // const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  // const {t} = useTranslation();

  // function getSessionStorageOrDefault(key, defaultValue) {
  //   const stored = sessionStorage.getItem(key);
  //   if (!stored) {
  //     return defaultValue;
  //   }
  //   return JSON.parse(stored);
  // }

  // useEffect(() => {
  //   if (relationshipsData) {
  //     setRelationships(relationshipsData.listRelationships.relationships);
  //   }

  // }, [relationshipsData, isFeedbackModalState]);

  // useEffect(() => {
  //   if(!authContext.user.isOnboardingComplete){
  //     console.log("start onboarding!")
  //   }
  //   refetch();
  // }, [authContext.user.id])
  // const addRelationshipDisabled = false; // REMOVING PAYWALL 7.10.2023
  // console.log ("authContext!! , " , authContext)
  // return (
  //   <>
  //     <FeedbackModal
  //       isFeedbackModalState={isFeedbackModalState}
  //       setIsFeedbackModalState={setIsFeedbackModalState}
  //     />

  //     {/* <Onboarding relationships={relationships} authContext={authContext}/> */}

  //     <SubscriptionModal isOpen={isSubscriptionModalOpen}
  //                        onExit={() => setIsSubscriptionModalOpen(false)}
  //     />
  //     <TopNavbar
  //       showNavBG={false}
  //       showLogo={false}
  //       authContext={authContext}
  //       user={authContext.user}
  //       border={false}
  //     ></TopNavbar>
  //     <div className="homepage pageLeftRightPadding noselect">
  //       <div className="homepage-content">
  //         <div className="homepage-content--top">
  //           <HeroPoster
  //               pathname={CourseData.pathname}
  //               topLine={t(CourseData.topLine)}
  //               name={t(CourseData.name)}
  //               description={t(CourseData.description)}
  //               imgPath={CourseData.imgPath}
  //           />
  //         </div>
  //         <div className="homepage-content--bottom texture">
  //           <div className="felt--container">
  //             <div className="felt--container--side-a">
  //               <div className="felt--container--text">
  //                 <h1 className="homepage__main-question noselect">{t(`homepage.main-question`)}</h1>
  //                 <p className=" noselect">{t('homepage.explainTerm')}</p>
  //               </div>
  //               <ul className="felt__list noselect">
  //                 {relationships.map((relationship, index) => {
  //                   return (
  //                     <Link
  //                       key={index}
  //                       to={{
  //                         pathname: `/relationship/${relationship.id}`,
  //                         state: {relationship}
  //                       }}
  //                     >
  //                       <li className="felt__list-item fade-in">
  //                         <AnimalsThumbnail
  //                           animalId={relationship.animalId}
  //                           partnerAnimalId={relationship.partnerAnimalId}
  //                         ></AnimalsThumbnail>
  //                         <p>
  //                           {t(`homepage.meAndPartner`, {
  //                             name: relationship.name,
  //                             partnerName: relationship.partnerName
  //                           })}
  //                         </p>
  //                       </li>
  //                     </Link>
  //                   );
  //                 })}
  //                 <Link className="add-button" to={!addRelationshipDisabled ? `/relationship/new` : ''}>
  //                   <li className="felt__list-item felt__list-item--create">
  //                     <button
  //                       onClick={addRelationshipDisabled ? () => {
  //                         setIsSubscriptionModalOpen(true)
  //                       } : null}>{`+`}</button>
  //                     <p>{t(`homepage.addRelationship`)}</p>
  //                   </li>
  //                 </Link>
  //                 {console.log("relationships", relationships)}
  //                   <Link to={!addRelationshipDisabled ? `/relationship/new` : ''}>
  //                     <li className="felt__list-item felt__list-item--placeholder--container" onClick={addRelationshipDisabled ? () => {
  //                         setIsSubscriptionModalOpen(true) 
  //                       } : null}>
  //                       <div className="felt__list-item--placeholder"></div>
  //                     </li>                
  //                     </Link>
  //               </ul>
  //             </div>
  //             <div className="felt--container--side-b">
  //               <img
  //                     alt={`catbearsAngry`}
  //                     src={catbearsAngry}
  //                     srcSet={`${catbearsAngry2x} 2x, ${catbearsAngry2x} 3x`}
  //                     className="catbearsAngry"
  //                     />
  //             </div>
  //           </div>
  //         </div>
  //         </div>
  //       </div>
    // </>
  // );
}

export default HomePage;

import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import Xbutton from "components/Xbutton";
import StepRequirement from 'components/StepRequirement/StepRequirement';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import './LessonLobby.scss'
import printFunc from 'utils/printFunc';
import FadeToBlackTransition from 'components/Transitions/FadeToBlackTransition';
import LessonOnboarding from './LessonOnboarding';


const LessonLobby = ({
            activityPages,
            teaserThumbnail, 
            name, 
            teaserUrl, 
            stepRequirements, 
            onContinue, 
            VolumeSwitchState,
            onXbutton,
            LessonStarted,
            activityPageThumbnail
    }) => {
    const {t} = useTranslation();
    setPropertyVh();

    const onExit = () => {
        setVideoPlayer(false)
    }

 
    const handleOnPrint = () => {
        printFunc(activityPages)
    }
    
    const [fadeToVideo, setFadeToVideo] = useState(false)
    const handleOnContinue = () => {
        LessonStarted ? onContinue() : setFadeToVideo(true)
    }
    
    const runOnContinue = () => {
        onContinue()
    }


    const onOpenVideo = () => {
            setVideoPlayer(true)
    }


    const [videoPlayer, setVideoPlayer] = useState(false)
    return (
        <>
        <FadeToBlackTransition 
            shouldStart={fadeToVideo}
            setShouldStart={setFadeToVideo}
            functionToFire={()=>setVideoPlayer(true)}
            duration={1400}
        />
        {videoPlayer && 
            <div className="lessonLobby-container--video">
                <VideoPlayer
                    URL={teaserUrl}
                    onExit={onExit}
                    onContinue={LessonStarted ? onExit : onContinue}
                    IsCurrentSelectedStepItemCompleted={true}
                    volume={VolumeSwitchState}
                    VideoPlayerLoop={false}
                />
          </div>
          }     
          {!videoPlayer &&  
          <>              
        
        <Xbutton fixed={true} onClose={onXbutton ? onXbutton : undefined}/>
        {!LessonStarted && <LessonOnboarding 
            stepRequirements={stepRequirements}
            activityPages={activityPages}
            onFinish={runOnContinue}
        />}
        {LessonStarted && <div className={"lessonLobby-container"}>
            <div className={"lessonLobby-content noselect"}>
                <div className={"lessonLobby-content-a"}>
                    <div className={"lobby-title"}>{name}</div>
                    {/* <div className={"lobby-subtitle"}>{description}</div> */}
                    {LessonStarted && 
                        <div className="teaserThumbnail" onClick={onOpenVideo}>
                        {/* <div className="teaserThumbnail-instructor">
                            <Instructor 
                                    AnimalType ={AnimalType} 
                                    instructorName ={instructorName}
                                    intructorBottom = "-84px"
                                    intructorSize = "280px"
                                    intructorNameSize = "20px"
                                    // intructorNameColor = "red"
                                    intructorNameMargin = "0px"
              
                                />
                        </div> */}
                        <div className="teaserThumbnail-btn">
                            <div className="teaserThumbnail-btn_img">
                                <img className="teaserThumbnail-btn_img" src="/assets/icons/play-icon.png" />
                            </div>
                            <div className="teaserThumbnail-btn_txt">{t("general.watchIntro")}</div>
                        </div>
                        <div className="teaserThumbnail-thumb">
                            <img src={teaserThumbnail} alt="video thumbnail" />
                        </div>
                    </div>}
                </div>
                <div className={"lessonLobby-content-b"}>
                    <div className={"content-b_lobby-title"}>{t("general.prepForLesson")}</div>
                    {!LessonStarted && <div className={"content-b_lobby-subtitle"}>{t("general.prepForLessonSubtitle")}</div>}
                    <div className="stepRequirements--Lobby-container">
                        {stepRequirements.map((Requirement, index) => {
                                return (
                                    <div className="req-container " key={index}>
                                        <StepRequirement
                                            key={index}
                                            type={Requirement.name}
                                            showDescription
                                            imgPath={activityPageThumbnail}
                                            RadioButton={!LessonStarted}
                                            hideName={Requirement.name === "activityPage"}
                                            sizeM
                                            />
                                        {Requirement.name === "activityPage" && 
                                            <button onClick={handleOnPrint} className={"buttonCatbears buttonCatbears_CTA req-container_miniButton"}>
                                                <img src="/assets/icons/icon-print.svg" />
                                                {t("general.printActivityPages")}
                                            </button> 
                                        }
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
            <div className={"lessonLobby-footer"}>
                <div className={"lessonLobby-footer-2buttons"}>
                <button onClick={handleOnPrint} className={" buttonCatbears buttonCatbears_CTA "}>
                    <img src="/assets/icons/icon-print.svg" />
                    {t("general.printActivityPages")}
                </button>          
                <button onClick={handleOnContinue} className={" buttonCatbears "}>
                    {LessonStarted ? " ": <img src="/assets/icons/icon_play-circle-white.svg" />}
                    {LessonStarted ? t("general.backToLesson"): t("general.startLesson")}
                </button>
                </div>
            </div>
        </div>}
        </>
        }
     </>
    )

}

export default LessonLobby
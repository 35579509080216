import { useState, useEffect } from 'react';

function FadeToBlackTransition({ shouldStart, setShouldStart, functionToFire, duration }) {
    const [visible, setVisible] = useState(false);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        if (shouldStart) {
            setVisible(true);
            const fadeInTimeout = setTimeout(() => setOpacity(1), 10); // Start fade-in
            console.log("on")
            const transitionTimer = setTimeout(() => {
                setOpacity(0); // Start fading out
                setTimeout(() => {
                    functionToFire(); // Fire the function
                }, 10); // Wait for fade-out to complete
            }, duration); // Wait for fade-in to complete
            
            return () => {
                clearTimeout(transitionTimer);
                clearTimeout(fadeInTimeout);
                setVisible(false); // Hide the div
                setShouldStart(false); 
                console.log("off")
                setOpacity(0)
            }
        }
    }, [shouldStart, setShouldStart, functionToFire, duration]);

    return (
        <div
            style={{
                display: visible ? 'block' : 'none',
                opacity: opacity,
                transition: `opacity ${duration}ms`,
                backgroundColor: 'black',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 999999,
                top: 0,
                left: 0,
            }}
        ></div>
    );
}

export default FadeToBlackTransition;

import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import './LessonVideoItem.scss';

function LessonVideoItem({name, onClick, setLessonVideoItemURL, videoUrl, ImageToShow, CurrentSelectedStep, size, state, isCurrent, title, description, imgPath, activityPagePath, AnimalType, instructorName, badge, stepsCount}){
    const {t} = useTranslation();

    const handleSetVideoUrl = () => {
      setLessonVideoItemURL(videoUrl)
      onClick();
    }
    return(
            <div className={(state === "disabled" ? "disabledClick" : undefined) + " LessonVideoItem " + (CurrentSelectedStep && "lesson-video-item__coin-current ")} onClick={handleSetVideoUrl}>
                <div className={" lesson-step-item__coin--img " }>
                    <img className={"LessonVideoItem-imageToShow " } src={"/assets/coins/coin_" + ImageToShow + (state=="disabled" ? "_off.svg": "_on.svg")}/>
                </div>
                <div className="LessonVideoItem-title ">{name}</div>
                <div className="LessonVideoItem-subtitle ">{t("general.teamworkSkill")}</div>
            {/* <div className={[
                  "LessonVideoItem__container--list-item ",
                  ("LessonVideoItem__container_" + size),
                  (isCurrent && "LessonVideoItem__container_current "),
                  (state=="completed" && "LessonVideoItem__container_completed")
                  ].join(" ")}>
                  
                  
                  {isCurrent && <div className='ArrowPointer'><img  src="/assets/icons/icon_arrow-down.svg"/></div>}
                  <div className={["lessonImg-container", (isCurrent && "backgroundGlow shadowGlow"), (state=="disabled" && "lessonImg-container-disabled")].join(" ")} >
                  <div className="LessonVideoItem__title">{title}</div>  
                  {state=="completed" && <img className="Lesson_Trophy bounceDelayed" alt={`Lesson complete!`} src={"assets/icons/icon_trophy.png"} />}
                  </div>
                  </div> */}
            </div>
    )
}


export default LessonVideoItem;


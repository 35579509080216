import React, {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from "react-apollo";
import gql from "graphql-tag";
import {login} from "../../graphql/mutations";
import Register from "./Register";
import Modal from "../../components/Modal/Modal";
import UserContext from "./UserContext";
import RestorePassword from "./RestorePassword";
import "./Catbears_Login.scss"

const Catbears_Login = ({toggleDisplayLogin, onLogin, onClose, shouldAllowSwitchToRegister, getIsRegisterMode=false}) => {
  const {user, handleUserUpdate, saveUserToLocalStorage, setAuthData} = useContext(UserContext)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegisterMode, setIsRegisterMode] = useState(getIsRegisterMode);
  const [isResetPassword, setIsResetPassword] = useState(false);

  const {t} = useTranslation();

  const [loginMutation, {loading}] = useMutation(gql(login))

  const _onLogin = async (event) => {
    event.preventDefault();
    try {
      const result = await loginMutation({variables: {email, password}})
      const resultData = result.data.login;
      const {token, user} = resultData
      saveUserToLocalStorage(token, user.id);
      setAuthData(resultData);
      onLogin({email, password})

    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  }

  return (
    <>
        <div className={"Onboarding-title"}>{t("loginFlow.logInCatbears")}</div>
        <div className={"Onboarding-subtitle"}>{t("loginFlow.logInCatbearsSubtitle")}</div>
        <form onSubmit={_onLogin} className="Catbears_Register">
            <div className={"modal__form--error-msg " + (error ? "" : " displayNone ")}>
            {error}
            </div>
            <div>
            <label className={"register-label noselect "}>
                {t("loginFlow.email")}
            </label>
            <input type="email"
                    className="input input-authMenu "
                    value={email}
                    onChange={e => setEmail(e.target.value)}/>
            </div>
            <div>
            <label className={"register-label noselect "}>
                {t("loginFlow.password")}
            </label>
            <input type="Password" className="input input-authMenu" value={password}
                    onChange={e => setPassword(e.target.value)}/>
            </div>
            <button className={"noselect buttonCatbears buttonCatbears_CTA register-btn"}
                    type="submit">{loading ? "" : t("loginFlow.logInBtn")}
            </button> 
        </form>
        <div className="below-register-form noselect ">
            <div className="createAccount-container">
            <p>{t("loginFlow.FirstTimeHere") + " "}
                <a className="catbearsLink"
                onClick={toggleDisplayLogin}> 
                {t("loginFlow.createAnAccount")}
                </a>
            </p>
            </div>
            <a className="noselect catbearsLink " onClick={() => setIsResetPassword(true)}>
               {t("loginFlow.forgotPassword")}
            </a>
            {/* <div className="noselect modal__below-submit--micro-text ">{t("loginFlow.byLogging") + " "}
            <a className="noselect link_no-button register_mini-text register_login-link ">{t("loginFlow.privacyPolicy")}</a>
            {" " + t("loginFlow.and")}
            <a className="noselect link_no-button register_mini-text register_login-link ">{t("loginFlow.TermsOfService")}</a>
            {" " + t("loginFlow.endOfSentence")}.
            </div> */}
        </div>
    </>
  )
}


export default Catbears_Login;
import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
// import './Onboarding_WhereActivity.scss'

const Onboarding_WhereActivity = ({
            // onExit,
            onBack,
            onContinue,            
    }) => {
    const {t} = useTranslation();
    setPropertyVh();

    return (
        <>
        <div className={"Onboarding-container"}>
                    <div className={"Onboarding-title"}>איפה אתם יוצרים היום?</div>
                    <div className={"Onboarding-subtitle"}>בבית, בבית הספר, במקום אחר</div>
                        {/* <div className="teaserThumbnail" >
                            <div className="teaserThumbnail-thumb" >
                            <img src={"/thumbnail-first-time-here.jpg"} alt="video thumbnail" />
                        </div> */}
                    {/* </div> */}
                    <div className='Room'></div>
                </div>
            <div className={"Onboarding-footer"}>
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                    {t("general.watchVideo")}
                </button>          
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary"}>
                    {t("general.watchedAndContinue")}
                </button>
            </div>
        </>
        )

}

export default Onboarding_WhereActivity
import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import StepRequirement from 'components/StepRequirement/StepRequirement';
import printFunc from '../../utils/printFunc';
import CourseTheatreReicpe from "../../flows/CourseTheatreRecipe";

const Onboarding_Print = ({
    // onExit,
    onBack,
    onContinue,            
}) => {
    const {t} = useTranslation();
    setPropertyVh();

    const activityPages = CourseTheatreReicpe.lessons[0].activityPages

    const handleOnPrint = () => {
        printFunc(activityPages)
    }

    return (
        <>
            <div className={"Onboarding-container"}>
                    <div className={"Onboarding-title"}>{t("onboarding.Onboarding_Print.title")}</div>
                    <div className={"Onboarding-subtitle"}>{t("onboarding.Onboarding_Print.subtitle")}</div>
                    <div className="content-ab content-column ">
                        <StepRequirement
                            onClick={handleOnPrint}
                            key={1}
                            type={"activityPages"}
                            whiteText
                            sizeXL
                            hideName
                            showDescription
                            hideBackground
                        />
                        <button onClick={handleOnPrint} className={"buttonCatbears buttonCatbears_CTA req-container_miniButton"}>
                            <img src="/assets/icons/icon-print.svg" />
                            {t("general.printActivityPages")}
                        </button> 
                    </div>
                    <div className='Room'></div>
            </div>
            <div className={"Onboarding-footer"}>
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                    {t("onboarding.Onboarding_Print.cta")}
                </button>          
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary"}>
                {t("onboarding.Onboarding_Print.cta2")}
                </button>
            </div>
        </>
        )

}

export default Onboarding_Print
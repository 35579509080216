import React, {useState} from "react";
import Animal from "components/Animal/Animal";
import "./LessonStepItem.scss"
import { useTranslation } from 'react-i18next';



function LessonStepItem({
     buttonShape,
     name,
     type,
     size,
     index,
     stepState,
     CurrentSelectedStep,
     setCurrentSelectedStep,
     setCurrentSelectedStepItem,
     CelebrationState,
     classes,
     AnimalName,
     coinName,
     StepNumberToShow,
     lessonCompleted,
     celebrationMoment,
     ImageToShow
    }) {

    const clickOnButton = () => {
        setCurrentSelectedStep(index)
        setCurrentSelectedStepItem(0)
        console.log("step "+ index + " pressed")
        // new Audio('/assets/audio/tap.mp3').play()
    }
    const {t} = useTranslation();

    return(
        <>
            
            {buttonShape == "circle" &&
                <>
                    <div onClick={clickOnButton} className={[
                        "noselect",
                        "lesson-step-item__coin--container",
                        ("lesson-step-item__coin__state-" + stepState),
                        ((CurrentSelectedStep === index) ? " lesson-step-item__coin-current " : " " ),
                        (celebrationMoment && " bounceToLarge")
                        ].join(" ")
                        }>
                        <div className={"lesson-step-item__coin--img lesson-step-item__coin " + ((CurrentSelectedStep === index) ? " backgroundGlow shadowGlow " : " " ) }>
                            <img className={"shadowGlow"} src={"/assets/coins/coin_" + coinName + "_on.svg"} alt="theCatbears-sel" />
                        </div>
                        <div className="lesson-step-item--Skillname ">{name}</div>
                        <div className="lesson-step-item--smallText ">{t("general.teamworkSkill")}</div>

                    </div>
                </>
            }
            {buttonShape == "rectangle" &&
                <>
                <div onClick={clickOnButton} className={
                        "noselect lesson-step-item lesson-step-item__state-" + 
                        stepState + 
                        (coinName ? " lesson-step-item__coin " : "") +
                        ((CurrentSelectedStep === index) ? " lesson-step-item-current backgroundGlow shadowGlow " : " " )+ 
                        " lesson-step-item__size-" + (size) + 
                        " lesson-step-item__state-" + CelebrationState + " " + 
                        classes
                    }>
                    {ImageToShow && 
                            <>
                                <img className="lesson-step-imageToShow" src={ImageToShow}/>
                            </>
                    }        
                    {AnimalName &&
                        <>
                            {AnimalName && (
                                <div className="lesson-step-img">
                                    <Animal id={AnimalName} alt="my animal" />
                                </div>
                            )}
    
                        </>
                    }
                    {StepNumberToShow &&
                        <div className="lesson-step-item--number">
                            {StepNumberToShow}
                        </div>
                    }
                    

                
                <div className="lesson-step-item--name ">{name}</div>
                {type == "conflict-simulator" && <div className="lesson-step-item--smallText ">{t("general.practice-what-we-leanered")}</div>}
            </div>
                </>
            }
            
        </> 
    )

}

export default LessonStepItem


import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import setPropertyVh from '../../utils/setPropertyVh';
import StepRequirement from 'components/StepRequirement/StepRequirement';

const Onboarding_Environment = ({
            // onExit,
            onBack,
            onContinue,            
    }) => {
    const {t} = useTranslation();
    setPropertyVh();

    return (
        <>
            <div className={"Onboarding-container"}>
                    <div className={"Onboarding-title"}>{t("onboarding.Onboarding_Environment.title")}</div>
                    <div className={"Onboarding-subtitle"}>{t("onboarding.Onboarding_Environment.subtitle")}</div>
                    <div className="content-ab content-ab_big-gap">
                            <StepRequirement
                                 key={1}
                                 type={"craftTable"}
                                 whiteText
                                 sizeXL
                                 showDescription
                                 hideBackground
                             /> 
                            <StepRequirement
                                 key={2}
                                 type={"iPad"}
                                 whiteText
                                 sizeXL
                                 showDescription
                                 hideBackground
                             /> 
                    </div>
                    <div className='Room'></div>
            </div>
            <div className={"Onboarding-footer"}>
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-secondary  catbearsCTA-primary Onboarding-button"}>
                    {t("onboarding.Onboarding_Environment.cta")}
                </button>          
                <button onClick={onContinue} className={"button Onboarding-button Onboarding-button-primary catbearsCTA-secondary"}>
                {t("onboarding.Onboarding_Environment.cta2")}
                </button>
            </div>
        </>
        )

}

export default Onboarding_Environment
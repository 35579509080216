import React , {useState, useEffect} from "react";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import setPropertyVh from '../../utils/setPropertyVh';
import { useTranslation } from 'react-i18next';
import "./Teamwork.scss"
import ReactPlayer from 'react-player';
import Titleblock from "components/PageBlocks/TitleBlock";
import FooterStickyBlock from "components/PageBlocks/FooterStickyBlock";
import PageWrapperBlock from "components/PageBlocks/PageWrapperBlock";
import ContentWrapperBlock from "components/PageBlocks/ContentWrapperBlock";
import Instructor from "components/Instructor/Instructor";
import InfoCard from "components/InfoCard/InfoCard";
import PostitNote from "components/PostitNote/PostitNote";
import BulletsBlock from "components/PageBlocks/BulletsBlock";
import TeamworkSkillCard from "components/TeamworkSkillCard/TeamworkSkillCard";

const Teamwork = ({
    URL, 
    playRefAudio,
    pauseRefAudio,
    teamworkMusicRef,
    addEvent,
    onContinue,
    onBack, 
    CurrentSelectedStepItem,
    bullets,
    tips,
    AnimalType,
    instructorName,
    LessonDetails,
    }) => {

        console.log("LessonDetailsLessonDetails: ", LessonDetails)
    useEffect(() => {
        playRefAudio(teamworkMusicRef, true);
    }, []);
    
    const extractBulletTexts = (bullets) => {
        return bullets.map(bullet => bullet.text);
      };
    setPropertyVh();
    const {t} = useTranslation();
    const [CHEATS, setCHEATS] = useState(0)
    // playRefAudio(teamworkMusicRef, true)

    function handleContinueTeamwork () {
        addEvent("continue", "stepItem", "Teamwork", CurrentSelectedStepItem)
        onContinue()
    }

    function handleOnBack () {
        addEvent && addEvent("back", "stepItem", "Teamwork", CurrentSelectedStepItem);
        onBack()
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
        event.preventDefault();
          switch (event.key) {
            case ' ':
            case 'Enter':
              handleContinueTeamwork();
              break;
            case 'Delete':
            case 'Backspace':
              handleOnBack();
              break;
            default:
              break;
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
      return (
          <>
          <PageWrapperBlock 
            backgroundColor = "var(--page-background-color)"
            ClassName={"teamwork"}
          >
            <ContentWrapperBlock
                ContentMaxWidth={"100%"}
                ContentPadding={"0 20px"}
            >
                <Titleblock 
                    title={t("general.letsCreate")}
                    titleFontSize={"33px"}
                />
                <div className={"teamwork-container"}>
                    <TeamworkSkillCard 
                        coinImgUrl={("/assets/coins/coin_" + LessonDetails.coinImgUrl + "_on.svg")}
                        name={t(LessonDetails.name)}
                        todo={t(LessonDetails.todo)}
                    />

                    
                    <div className={"info-cards"}>
                        {bullets && bullets.map((bullet, index) => (
                            <InfoCard 
                                key={index}
                                num={index+1}
                                imgUrl={bullet.img}
                                title={t(bullet.text)}
                            />
                        ))} 
                        {tips && <PostitNote 
                            children={
                                <>
                                <h1>{t("general.tips")}</h1>
                                <BulletsBlock
                                    fontSize={20} 
                                    bullets={tips}
                                />
                                </>
                            }
                        />}         
                    </div>
                    <div className={"teamwork-notes"}>
                        {/* {LessonDetails && <PostitNote 
                            children={
                                <>
                                <h1>{t(LessonDetails[0])}</h1>
                                <p>{t(LessonDetails[1])}</p>
                                </>
                            }
                        />} */}
          
                        {/* {tips && <PostitNote 
                            children={
                                <>
                                <h1>{t("general.tips")}</h1>
                                <BulletsBlock
                                    fontSize={20} 
                                    bullets={tips}
                                />
                                </>
                            }
                        />} */}
                    </div>
                   {/* <h2>{t("general.helpFriends")}</h2> */}
                </div>
            </ContentWrapperBlock>
            <FooterStickyBlock 
                    button1={{ text: t("general.back"), className: "catbearsCTA catbearsCTA-secondary", onClick: handleOnBack, iconStart: "/assets/icons/icon_arrow-forward-black.svg" }}
                    button2={{ text: t("general.continue"), className: "catbearsCTA catbearsCTA-primary", onClick: handleContinueTeamwork, iconEnd: "/assets/icons/icon_arrow-forward.svg" }}
                    backgroundColor = "var(--page-background-color)"
                    backgroundBright
                />
          </PageWrapperBlock>
        </>
    )
}

export default Teamwork

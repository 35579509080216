import React, { useState } from 'react';
import './TeamworkSkillCard.scss';

const TeamworkSkillCard = ({ coinImgUrl, name, todo }) => {
    return (<>
            <div className="teamworkSkillCard">
                    <div className="teamworkSkillCard_start">
                        <div className="teamworkSkillCard_coin">
                            <img src={coinImgUrl} alt={name} />
                        </div>
                    </div>
                    <div className="teamworkSkillCard_end">
                        <div className="teamworkSkillCard_name">{name}</div>
                        <div className="teamworkSkillCard_todo">{todo}</div>
                    </div>
                </div>
        </>)
}

export default TeamworkSkillCard;
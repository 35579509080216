import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useMutation} from 'react-apollo';
import gql from 'graphql-tag';
import TopNavbar from 'components/TopNavbar/TopNavbar';
import Loading from '../../components/Loading/Loading';
import LessonListItem from 'components/LessonListItem/LessonListItem';
import LessonVideoItem from 'components/LessonVideoItem/LessonVideoItem';
import LessonStepItem from 'pages/Lesson/LessonStepItem';
import SideBarPanelNav from '../../components/SideBarPanelNav/SideBarPanelNav';
import SubscriptionModal from '../../components/SubscriptionModal/SubscriptionModal';
import UserContext from "../../pages/Login/UserContext";
import {updateUserProgress} from '../../graphql/mutations';
import CourseTheatreRecipe from "../../flows/CourseTheatreRecipe";
import { GlobalContext } from "context/GlobalContext";
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';

import './Lessons.scss';
import {
  getProgressAsArr, 
} from "utils/courseFunctions"

function Lessons({user, authContext}) {
  
  const [CHEATS, setCHEATS] = useState(0)
  const [CHEATSprogress, setCHEATSprogress] = useState(user.userProgress)
  
  const userID = user.id;
  const {t} = useTranslation();

  const thisCourseNumber = 0;
  const LessonsRecipe = CourseTheatreRecipe.lessons;

  const [UserProgress, setUserProgress] = useState(); 

  const {VolumeSwitchState, setVolumeSwitchState} = useContext(GlobalContext);

  const [pageBackgroundColor, setPageBackgroundColor] = useState("blue");

  useEffect(() => {
    if (user) {
      const initialProgress = user.userProgress || {};
      setUserProgress(getProgressAsArr(initialProgress));
  
      [coursesProgress, lessonsProgress, stepsProgress, stepItemsProgress] = (UserProgress || [0, 0, 0, 0]);
  
      // Get the new background color and update the state
      const newColor = CourseTheatreRecipe.lessons[lessonsProgress]?.backgroundColor || "blue";
      setPageBackgroundColor(newColor);
    }
  }, [user, setUserProgress]);
  

  // console.log("UserProgress ", UserProgress)

let [coursesProgress, lessonsProgress, stepsProgress, stepItemsProgress] = (UserProgress || [0,0,0,0])
  // Array.isArray(user.userProgress) && user.userProgress.length > 0
  //     ? user.userProgress
  //     : {};
const [ShouldCelebrateLessonCompleted, setShouldCelebrateLessonCompleted] = useState(false);

  const getLessonState = (lessonIndex) => {
    //check if entire course is completed
    if(coursesProgress <= thisCourseNumber){
      //check lesson state
      if (lessonsProgress == lessonIndex) {
        return 'current';
      } else if (lessonsProgress > lessonIndex) {
        return 'completed';
      } else {
        return 'disabled';
      }
    } else {
      return 'completed';
    }    
};

//

//
document.documentElement.style.setProperty("--page-background-color",pageBackgroundColor);

const { handleUserUpdate } = useContext(UserContext);

const [updateUser, { data, loading, error }] = useMutation(gql(updateUserProgress), {
  update(cache, { data: { setUserProgress } }) {
    if (!setUserProgress) {
      console.error('setUserProgress is undefined or null');
      return;
    }
    handleUserUpdate(setUserProgress);
  },
});

const UpdateUserProgressFunc = async (someProgress) => {
  try {
    await updateUser({ variables: { userProgress: someProgress } });
    console.log('User progress updated successfully.');
  } catch (error) {
    console.error('Error updating user progress:', error);
  }
};

const exampleProgress = {
  courses: [
    {
      name: "Theatre",
      completed: false,
      lessons: [
        {
          completed: true,
          steps: [
            {
              completed: true,
              stepItems: [
                { completed: true },
                { completed: false },
              ],
            },
            {
              completed: false,
              stepItems: [],
            },
          ],
        },
        {
          completed: false,
          steps: [
            {
              completed: false,
              stepItems: [],
            },
            {
              completed: false,
              stepItems: [],
            },
          ],
        },
        {
          completed: false,
          steps: [],
        },
        {
          completed: false,
          steps: [],
        },
      ],
    },
  ],
};

// const delay = (ms) => {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// };

  
//   const [isHeLangState, setIsHeLangState] = useState(false);
const [videoPlayer, setVideoPlayer] = useState(false)
const [LessonVideoItemURL, setLessonVideoItemURL] = useState("")

const onExit = () => {
  setVideoPlayer(false)
}
const onOpenVideo = () => {
  setVideoPlayer(true)
}

  return (
    <>
      {CHEATS ? 
        <>
        <div>
        </div>
        </>
      : ""
      }
      {videoPlayer && 
          <div className="lessonLobby-container--video">
              <VideoPlayer
                  URL={LessonVideoItemURL}
                  onExit={onExit}
                  onContinue={onExit}
                  IsCurrentSelectedStepItemCompleted={true}
                  volume={VolumeSwitchState}
                  VideoPlayerLoop={false}
              />
        </div>
      }
      {!videoPlayer &&
      <>
      <TopNavbar
        showNavBG={true}
        showLogo={false}
        authContext={authContext}
        user={user}
        title={t(`course.theatre.courseName`)}
        // BackLinkTo={'/'}
        backgroundColor={pageBackgroundColor}
        border={true}
        opacity={'0.93'}
        texture={true}
        showMenuButton
        VolumeSwitchState = {VolumeSwitchState} 
        setVolumeSwitchState = {setVolumeSwitchState}
      ></TopNavbar>
      <div className="course--container  course--container__theatre noselect texture">
       
      <div className="Lessons__list ">
        {LessonsRecipe.map((lessonRecipe, index) => (
          <React.Fragment key={index}>
            {lessonRecipe.type === "LessonListItem" && (
              <LessonListItem 
                key={index}
                pathname={lessonRecipe.pathname}
                progressStatus={"completed"}
                title={t(lessonRecipe.name)}
                description={t(lessonRecipe.description)}
                activityPagePath={lessonRecipe.activityPagePath}
                imgPath={lessonRecipe.imgPath}
                instructor={lessonRecipe.instructor}
                AnimalType={lessonRecipe.AnimalType}
                instructorName={t(lessonRecipe.instructorName)}
                state={getLessonState(index)}
                isCurrent={lessonsProgress === index}
                badge={lessonRecipe.badge}
                size={lessonRecipe.size}
                stepsCount={lessonRecipe.steps.length}
              />
            )}
            {lessonRecipe.type === "videoTeamwork" && (
              <LessonStepItem
                key={index}
                buttonShape={lessonRecipe.buttonShape ? lessonRecipe.buttonShape : "rectangle"}
                name={t(lessonRecipe.name)}
                size={lessonRecipe.size}
                type={lessonRecipe.type}
                stepState={getLessonState(index)}
                CurrentSelectedStep={lessonsProgress === index}
                AnimalName={lessonRecipe.AnimalName}
                coinName={lessonRecipe.coinName}
                StepNumberToShow={lessonRecipe.StepNumberToShow ? lessonRecipe.StepNumberToShow : null}
                ImageToShow={lessonRecipe.ImageToShow ? lessonRecipe.ImageToShow : null}
                stepsCount={lessonRecipe.steps.length}
              />
            )}
            {lessonRecipe.type === "LessonVideoItem" && (
              <LessonVideoItem 
                key={index} 
                setLessonVideoItemURL={setLessonVideoItemURL}
                name={t(lessonRecipe.name)}
                ImageToShow={lessonRecipe.ImageToShow ? lessonRecipe.ImageToShow : null}
                state={getLessonState(index)}
                onClick={onOpenVideo}
                videoUrl={lessonRecipe.url}
                CurrentSelectedStep={lessonsProgress === index}
              />
            )}
          </React.Fragment>
        ))}


        </div>
      </div>
      </>
      }
    </>
  );
}

export default Lessons;

import {useTranslation} from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FullScreenStepper from 'components/FullScreenStepper/FullScreenStepper';
import Onboarding_Environment from 'components/Onboarding/Onboarding_Environment';
import Onboarding_Materials from 'components/Onboarding/Onboarding_Materials';
import Onboarding_Print from 'components/Onboarding/Onboarding_Print';

const LessonOnboarding = ({stepRequirements, activityPages, onFinish}) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  return (
    <>
        <FullScreenStepper
            steps={[
              <Onboarding_Environment/>,
              <Onboarding_Materials stepRequirements={stepRequirements}/>,
              <Onboarding_Print activityPages={activityPages}/>,
   
            ]}
            onFinish={onFinish}
            onExit={() => console.log('Exit!')}
        />
    </>
  )
}


export default LessonOnboarding;
